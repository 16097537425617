// You can add global styles to this file, and also import other style files
@use '@angular/material' as mat;

html,
body {
    height: 100%;
}

body {
    background-color: var(--color-background);
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    margin: 0;
}

.hide {
    visibility: hidden;
}

.hover-pointer:hover {
    cursor: pointer !important;
}

.header {
    .spacer {
        flex: 1 1 auto;
    }

    [mat-icon-button] {
        margin-left: 20px;
    }
}

.mdc-button {
    letter-spacing: normal !important;
}

.formular-card {
    margin-bottom: 2%;
    margin-right: 2%;

    .mat-mdc-card-subtitle {
        font-size: 10px;
        margin: 0 !important;
    }
}

//for time picker!
.timepicker-overlay {
    z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
    z-index: 1100 !important;
}

.mat-mdc-card-header {
    padding: 0 !important;
}

mat-card {
    padding: 16px;
}

.alert-warning {
    background-color: #fff3cd;
    border: 1px solid transparent;
    border-color: var(--ion-color-warning) !important;
    border-radius: 1rem;
    color: #967b31;
    font-size: medium;
    margin-bottom: 25px;
    margin-right: 35px;
    padding: 0.75rem 1.25rem;
    text-align: center;
}

.message-area.active {
    min-width: 50px !important;
}

// Anpassung Inputs da appearance="standard" nicht mehr gibt ab Angular Material Version 15
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
    padding: 0 0 0 10px;
}

// Anpassung Inputs da appearance="standard" nicht mehr gibt ab Angular Material Version 15
.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: white !important;
    padding: 0 0 0 10px;
}

// Anpassungen für die Chips bei Debitor, Household, RealEstate sonst wird immer ein Icon davor angezeigt
.mdc-evolution-chip__graphic.mat-mdc-chip-graphic.ng-star-inserted {
    display: none !important;
    visibility: hidden !important;
}

// Anpassungen für die Chips bei Debitor, Household, RealEstate sonst wird immer ein Icon davor angezeigt
.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    margin-left: 16px;
}

// Anpassung das der mat-card-subtitle in der selben Reihe ist wie mat-card-title
.mat-mdc-card-header-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
}

mat-hint {
    color: #0000008a;
    font-size: 85%;
    line-height: 1.125;
}

.mat-mdc-form-field-hint-wrapper {
    padding: 0 !important;
}

// Anpassung Dialog
.mat-mdc-dialog-container .mdc-dialog__surface {
    height: auto !important;
    min-width: 15vh !important;
}

// Anpassung Dialog
.mat-dialog-content {
    display: block;
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 65vh;
    overflow: auto;
}

// Anpassung Dialog
// .mat-mdc-dialog-container {
//     max-width: fit-content !important;
// }

// Anpassung Farbe von Radio Buttons
.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: #007a91 !important;
}

// Anpassung Farbe von Radio Buttons
.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: #007a91 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    overflow-wrap: anywhere !important;
}

// Anpassung bei Radio Buttons, das kein Icon angezeigt wird, wenn angehackt
.mat-mdc-option .mat-pseudo-checkbox-minimal {
    display: none !important;
}

// Anpassung Farbe Checkbox
.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    background-color: #007a91 !important;
    border-color: #007a91 !important;
}

// Anpassung Farbe Slider
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow {
    background: #007a91 !important;
}

// Anpassung Farbe Slider
.mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--selected:enabled
    .mdc-switch__icon {
    fill: #007a91 !important;
}

// Anpassung Farbe Slider
.mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__icon {
    fill: #007a91 !important;
}

// Anpassung Hintergrundfarbe bei ausgewählten Radio (Rechenbeispiel)
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
        .mdc-list-item--disabled
    ) {
    background: none !important;

    .mdc-list-item__primary-text {
        color: inherit !important;
    }
}

.mat-chip-option-center {
    align-self: center;
}

finad-papers-tab > waiter-component > div {
    align-items: center;
    display: flex;
    justify-content: center;
}

finad-financing-map-tab > waiter-component > div {
    align-items: center;
    display: flex;
    justify-content: center;
}

waiter-dialog {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 170px;
}
