.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.signature-container {
    height: 80px;
    line-height: 50px;
    width: 100%;

    mat-icon {
        height: 100%;
        line-height: 50px;
        margin-right: calc(var(--base-margin) / 2);
    }

    small {
        position: absolute;
    }

    img {
        border: dashed 1px var(--ion-color-secondary);
        display: block;
        float: left;
        height: 100%;
        line-height: 50px;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }
}
