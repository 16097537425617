// sass-lint:disable-all

/* http://mcg.mbitson.com */

$md-primary: (
    50: #e0eff2,
    100: #b3d7de,
    150: #020094,
    200: #80bdc8,
    300: #4da2b2,
    400: #268ea2,
    500: #007a91,
    600: #007289,
    700: #006375,
    800: #005d74,
    900: #004a62,
    A100: #262626,
    A200: #666,
    A400: #cf3524,
    A700: #13baff,
    A800: #e8e8e8,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-secondary: (
    50: #ededed,
    100: #d1d1d1,
    200: #b3b3b3,
    300: #fff,
    400: #7d7d7d,
    500: #666,
    600: #5e5e5e,
    700: #535353,
    800: #494949,
    900: #383838,
    A100: #f49c9c,
    A200: #ef6e6e,
    A400: #ff2a2a,
    A700: #f11,
    contrast: (
        50: #000,
        100: #000,
        200: #262626,
        300: #000,
        400: #fff,
        500: #fff,
        600: #666,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #fff,
        A700: #fff,
    ),
);

$md-dark: (
    50: #e5e5e5,
    100: #bebebe,
    200: #939393,
    300: #676767,
    400: #474747,
    500: #262626,
    600: #222,
    700: #1c1c1c,
    800: #171717,
    900: #0d0d0d,
    A100: #000,
    A200: #e53c3c,
    A400: #e00,
    A700: #d40000,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #fff,
        A400: #fff,
        A700: #fff,
    ),
);

$md-light: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-disabled: (
    50: #f5f5f5,
    100: #f0f0f0,
    200: #e6e6e6,
    300: #dbdbdb,
    400: #d4d4d4,
    500: #ccc,
    600: #c7c7c7,
    700: #c0c0c0,
    800: #b9b9b9,
    900: #adadad,
    A100: #fff,
    A200: #fff,
    A400: #fffcfc,
    A700: #ffe2e2,
    contrast: (
        50: #ccc,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-success: (
    50: #e6f4eb,
    100: #c1e3cd,
    200: #97d1ac,
    300: #6dbf8a,
    400: #4eb171,
    500: #2fa358,
    600: #2a9b50,
    700: #239147,
    800: #1d883d,
    900: #12772d,
    A100: #acffbf,
    A200: #79ff97,
    A400: #46ff70,
    A700: #2cff5c,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-warning: (
    50: #fff6e0,
    100: #feeab3,
    200: #fddc80,
    300: #fccd4d,
    400: #fcc326,
    500: #fbb800,
    600: #fab100,
    700: #faa800,
    800: #f9a000,
    900: #f89100,
    A100: #fff,
    A200: #fff6eb,
    A400: #ffdeb8,
    A700: #ffd29f,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-danger: (
    50: #fcebea,
    100: #f9ceca,
    200: #f5aea6,
    300: #f08d82,
    400: #ed7468,
    500: #ea5c4d,
    600: #e75446,
    700: #e44a3d,
    800: #e14134,
    900: #db3025,
    A100: #fff,
    A200: #ffe4e3,
    A400: #ffb4b0,
    A700: #ff9c96,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-background: (
    50: #fff,
    100: #fafafa,
    200: #f3f3f3,
    300: #e5e5e5,
    400: #e0e0e0,
    500: #dbdbdb,
    600: #d5d5d5,
    700: #d0d0d0,
    800: #c9c9c9,
    900: #c0c0c0,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
    contrast: (
        50: #000,
        100: #f7f7f7,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #000,
        800: #000,
        900: #000,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-tertiary: (
    50: #f1f1f1,
    100: #ddd,
    200: #c6c6c6,
    300: #afafaf,
    400: #9d9d9d,
    500: #8c8c8c,
    600: #848484,
    700: #797979,
    800: #6f6f6f,
    900: #5c5c5c,
    A100: #fad3d3,
    A200: #f5a5a5,
    A400: #ff6868,
    A700: #ff4e4e,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #000,
        500: #000,
        600: #000,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$md-buttons-primary: (
    50: #007a91,
    100: #005d6d,
    200: #f5f5f5,
    500: #fff,
    700: #00272e,
    contrast: (
        50: #fff,
        100: #fff,
        200: #ccc,
        500: #fff,
        700: #fff,
    ),
);

$md-buttons-secondary: (
    50: #fff,
    100: #f5f5f5,
    200: #ececec,
    500: #d6d6d6,
    700: #afafaf,
    contrast: (
        50: #000,
        100: #919191, 
        200: #666,
        500: #fff,
        700: #fff,
    ),
);

$md-buttons-disabled: (
    50: #f5f5f5,
    100: #fff,
    200: #fff,
    500: #fff,
    700: #fff,
    contrast: (
        50: #ccc,
        100: #fff,
        200: #fff,
        500: #fff,
        700: #fff,
    ),
);
