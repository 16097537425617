// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core; // sass-lint:disable-line empty-args

@import './variables';
@import './fonts';

/* stylelint-disable function-parentheses-space-inside */
$ucba-theme: mat.define-light-theme(
    (
        color: (
            primary: $ucba-primary,
            accent: $ucba-secondary,
            warn: $ucba-danger
        )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ucba-theme);
@include mat.all-component-typographies($ucba-typography);
