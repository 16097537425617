@use '@angular/material' as mat;

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src:
        local('Material Icons'),
        local('MaterialIcons-Regular'),
        url('../assets/fonts/Material-iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    font-feature-settings: 'liga'; /* Support for IE. */
    font-size: 24px; /* Preferred icon size */
    -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
    -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;

    &.md-small {
        font-size: small !important;
    }

    &.md-smaller {
        font-size: smaller !important;
    }

    &.md-large {
        font-size: large !important;
    }

    &.md-larger {
        font-size: larger !important;
    }

    &.md-18 {
        font-size: 18px;
    }

    &.md-24 {
        font-size: 24px;
    }

    &.md-36 {
        font-size: 36px;
    }

    &.md-48 {
        font-size: 48px;
    }
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    src:
        local('Unicredit Bold'),
        local('unicredit-bold'),
        url('../assets/fonts/UniCredit-fonts/unicredit-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: italic;
    font-weight: bold;
    src:
        local('Unicredit Bold Italic'),
        local('unicredit-bolditalic'),
        url('../assets/fonts/UniCredit-fonts/unicredit-bolditalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    src:
        local('Unicredit Heavy'),
        local('unicredit-heavy'),
        url('../assets/fonts/UniCredit-fonts/unicredit-heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: italic;
    font-weight: 900;
    src:
        local('Unicredit Heavy Italic'),
        local('unicredit-heavyitalic'),
        url('../assets/fonts/UniCredit-fonts/unicredit-heavyitalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: italic;
    font-weight: normal;
    src:
        local('Unicredit Italic'),
        local('unicredit-italic'),
        url('../assets/fonts/UniCredit-fonts/unicredit-italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    src:
        local('Unicredit Light'),
        local('unicredit-light'),
        url('../assets/fonts/UniCredit-fonts/unicredit-light.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: italic;
    font-weight: 300;
    src:
        local('Unicredit Light Italic'),
        local('unicredit-lightitalic'),
        url('../assets/fonts/UniCredit-fonts/unicredit-lightitalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    src:
        local('Unicredit Medium'),
        local('unicredit-medium'),
        url('../assets/fonts/UniCredit-fonts/unicredit-medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: italic;
    font-weight: 500;
    src:
        local('Unicredit Medium Italic'),
        local('unicredit-mediumitalic'),
        url('../assets/fonts/UniCredit-fonts/unicredit-mediumitalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Unicredit';
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    src:
        local('Unicredit Regular'),
        local('unicredit-regular'),
        url('../assets/fonts/UniCredit-fonts/unicredit-regular.ttf') format('truetype');
}

$ucba-typography: mat.define-typography-config(
    $font-family: Unicredit,
    $headline-1: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 500),
    $body-2: mat.define-typography-level(14px, 20px, 400)
);
